.packs-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    grid-row-gap: 60px;
    row-gap: 60px;
}

.packs-list-small {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
    grid-row-gap: 60px;
    row-gap: 60px;
}

@media screen and (max-width: 1510px) {
    .packs-list {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .packs-list:not(.packs-list-small) .widget-pack:nth-last-child(-n+3),
    .packs-list:not(.packs-list-small) .skeleton-pack:nth-last-child(-n+3) {
        display: none;
    }
}

@media screen and (max-width: 1270px) {
    .packs-list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .packs-list:not(.packs-list-small) .widget-pack:nth-last-child(-n+3),
    .packs-list:not(.packs-list-small) .skeleton-pack:nth-last-child(-n+3) {
        display: flex;
    }

    .packs-list:not(.packs-list-small) .widget-pack:nth-last-child(-n+2),
    .packs-list:not(.packs-list-small) .skeleton-pack:nth-last-child(-n+2) {
        display: none;
    }

    .packs-list-small {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .packs-list {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .packs-list:not(.packs-list-small) .widget-pack:nth-last-child(-n+2),
    .packs-list:not(.packs-list-small) .skeleton-pack:nth-last-child(-n+2) {
        display: flex;
    }

    .packs-list-small {
        grid-template-columns: repeat(4, 1fr);
    }

    .packs-list-small .widget-pack:nth-last-child(-n+3),
    .packs-list-small .skeleton-pack:nth-last-child(-n+3) {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .packs-list-small {
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 50px;
    }

    .packs-list-small .widget-pack:nth-last-child(-n+3),
    .packs-list-small .skeleton-pack:nth-last-child(-n+3) {
        display: flex;
    }
}

@media screen and (max-width: 575px) {
    .packs-list {
        grid-template-columns: 1fr 1fr;
    }

    .packs-list-small .widget-pack:nth-last-child(-n+1),
    .packs-list-small .skeleton-pack:nth-last-child(-n+1) {
        display: none;
    }
}

@media screen and (max-width: 375px) {
    .packs-list {
        grid-row-gap: 40px;
        row-gap: 40px;
    }

    .packs-list-small .widget-pack .title {
        font-size: 19px;
    }
}

