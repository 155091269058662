.skeleton-pack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skeleton-pack .MuiSkeleton-root {
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
}

.skeleton-pack .MuiSkeleton-circle {
    width: 116px;
    height: 116px;
}

.skeleton-pack.full .MuiSkeleton-circle {
    width: 95px;
    height: 95px;
}

.skeleton-pack .description {
    width: 100%;
}

.skeleton-pack .text-link {
    display: flex;
    justify-content: center;
    width: 100%;
}

@media screen and (max-width: 350px) {
    .skeleton-pack .MuiSkeleton-circle {
        width: 95px;
        height: 95px;
    }
}
