@import "../common_styles/colors";

.AccountSignLink {
  color: $grey-main-color;
  display: flex;
  font-size: 16px;
  line-height: 24px;

  a {
    color: #000;
    margin-left: 5px;
  }
}
