.ThankYou {
  margin-top: 163px;
  margin-bottom: 171px;

  .MainTitle {
    margin-bottom: 20px;
  }

  .InfoField,
  .InfoFieldWithLink {
    margin-bottom: 20px;

    // &:last-child {
    //   margin-bottom: 0;
    // }
  }

  .EmptyPageFiller {
    margin-top: 60px;
  }
}  

@media (min-width: 768px) {
  .ThankYou {
    .EmptyPageFiller {
      margin-top: 0;
    }
  }
}
