@import '../common_styles/colors';

.notifications-form {

  &__caption {
    max-width: 635px;
    margin-bottom: 45px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: $grey-dark-color;
  }

  &__link {
    color: black;
  }

  &__link:hover {
    color: black;
    opacity: 0.6;
  }

  &__button {
    background-color: black;
    border-radius: 10px;
    border-width: 0px;
    border-color: black;
    padding: 12px 23px;
    min-height: 50px;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    box-shadow: none;
    outline: none;
  }

  &__button:hover,
  &__button:active {
    background-color: black;
    opacity: 0.6;
    box-shadow: none;
    outline: none;
    border-width: 0px;
    border-color: black;
  }

  &__button:focus {
    background-color: black;
    box-shadow: none;
    outline: none;
    border-width: 0px;
    border-color: black;
  }

  &__explanation {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $grey-main-color;
    margin-bottom: 36px;
    margin-left: 30px;
  }

  &__explanation-last {
    margin-bottom: 45px;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-checkbox-wrapper {
    padding: 0;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #000000;

    span {
      padding: 0 10px;
    }

    .ant-checkbox {
      padding: 0;
      box-sizing: border-box;
      outline: none;
      box-shadow: none;
      top: -3px;

      .ant-checkbox-inner {
        border-color: #000;
        border-radius: 5px;
        box-sizing: border-box;
        width: 22px;
        height: 22px;
        padding: 0;
      }

      &-checked {
        .ant-checkbox-inner {
          background-color: #000;

          &::after {
            left: 30%;
          }
        }

        &::after {
          border: 1px solid #000;
          border-radius: 5px;
        }
      }

      &-disabled .ant-checkbox-inner {
        border-color: #999 !important;
        background-color: #999;
      }

      &-disabled {
        .ant-checkbox-inner {
          &::after {
            border-color: #fff;
          }
        }
      }

      &-disabled + span {
        color: $grey-dark-color
      }
    }
  }
}

@media (max-width: 575px) {
  .notifications-form {
    &__explanation {
      margin-left: 0;
    }
  }
}
