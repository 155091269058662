@import '../../common_styles/colors';

.notifications {
  margin-top: 65px;
  margin-bottom: 171px;
  --antd-wave-shadow-color: none;

  .MainTitle {
    margin-bottom: 30px;
  }

  &__caption {
    margin-bottom: 30px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: $grey-dark-color;
  }

  &__email {
    color: black;
  }
}
