@import "../common_styles/colors.scss";

.InfoFieldWithLink {
  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $grey-dark-color;
    margin-bottom: 4px;
  }

  &__link {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
    word-wrap: break-word;
    display: block;
  }
}
