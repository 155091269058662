.reset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  picture {
    display: block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
