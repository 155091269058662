@import '../common_styles/colors';

@mixin buttonColors($color, $hoverAmount, $activeAmount) {
  color: #fff;
  background-color: $color;
  border-color: $color;

  &:hover{
    color: #fff;
    background-color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:active {
    color: #fff;
    background-color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }
}

@mixin outlineButtonColors($color, $hoverAmount, $activeAmount) {
  background-color: #fff;
  color: $color;
  border-color: $color;

  &:hover{
    background-color: #fff;
    color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:active {
    background-color: #fff;
    color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }
}

.styledAntPopover {
  .ant-popover {
    &-inner {
      border-radius: 5px;

      &-content {
        .ant-popover-buttons {
          display: flex;
          justify-content: center;
      
          button {
            margin: 0 5px 0 0;
            border-radius: 5px;

            &:last-child {
              margin: 0;
            }

            &.ant-btn {
              @include outlineButtonColors($blue-color, 20, 20);

              &-primary {
                @include buttonColors($blue-color, 20, 20);
              }
            }
          }
        }
      }
    }

    &-message-title {
      font-weight: 500;
      text-align: center;
      padding-left: 0;
    }
  }

  &_destructive {
    @extend .styledAntPopover;

    .ant-popover {
      &-inner {
        &-content {
          .ant-popover-buttons {
            button {
              &.ant-btn {
                &-primary {
                  @include buttonColors($danger-color, 20, 20);
                }
              }
            }
          }
        }
      }
    }          
  }
}
