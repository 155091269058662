.TextAvatar, .TextAvatar_0, .TextAvatar_1, .TextAvatar_2, .TextAvatar_3, .TextAvatar_4, .TextAvatar_5, .TextAvatar_6, .TextAvatar_7 {
	width: 34px;
	height: 34px;
	display: inline-block;
	position: relative;
	border-radius: 50%;
	transition: background-color 0.3s ease-in-out;
}

.TextAvatar:hover, .TextAvatar_0:hover, .TextAvatar_1:hover, .TextAvatar_2:hover, .TextAvatar_3:hover, .TextAvatar_4:hover, .TextAvatar_5:hover, .TextAvatar_6:hover, .TextAvatar_7:hover {
	cursor: pointer;
}

.TextAvatar_0 {
	background-color: #7BC66E;
}

.TextAvatar_0:hover {
	background-color: #9cd493;
}

.TextAvatar_1 {
	background-color: #6AA2E0;
}

.TextAvatar_1:hover {
	background-color: #94bce9;
}

.TextAvatar_2 {
	background-color: #A95DBE;
}

.TextAvatar_2:hover {
	background-color: #bc81cd;
}

.TextAvatar_3 {
	background-color: #E57F60;
}

.TextAvatar_3:hover {
	background-color: #eca28c;
}

.TextAvatar_4 {
	background-color: #7F60C7;
}

.TextAvatar_4:hover {
	background-color: #9d86d4;
}

.TextAvatar_5 {
	background-color: #E5BB55;
}

.TextAvatar_5:hover {
	background-color: #eccd81;
}

.TextAvatar_6 {
	background-color: #9A705C;
}

.TextAvatar_6:hover {
	background-color: #af8b7a;
}

.TextAvatar_7 {
	background-color: #626F78;
}

.TextAvatar_7:hover {
	background-color: #7a8993;
}

.TextAvatar__letter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	line-height: 20px;
	color: #fff;
	text-transform: uppercase;
	user-select: none;
}
