@import "../common_styles/colors";

.LinksBlock {
  color: $grey-main-color;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  justify-content: space-between;

  a {
    color: #000;
    margin-left: 5px;

    &:hover {
      color: $grey-dark-color;
    }
  }
}
