/*widget-pack*/

.widget-pack .small img {
    width: 94px;
    height: 94px;
}

.widget-pack img {
    position: absolute;

    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 10;
    /*background-color: #fff;*/
}
.widget-pack .image-link {
    position: relative;

    width: 116px;
    height: 116px;

    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    cursor: default;

    transition: all 0.2s ease-in-out;
}

.widget-pack.small .image-link {
    width: 94px;
    height: 94px;
}

.widget-pack:not(.full) .image-link {
    cursor: pointer;
}

.widget-pack:not(.full) .image-link:hover {
    box-shadow: 0px 20px 50px rgb(0 0 0 / 20%) !important;
}

.widget-pack .small .image-link {
    width: 94px;
    height: 94px;
}
.widget-pack .image-link:after {
    content: '';
    position: absolute;
    background-image: url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/placeholder.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 38px;
    height: 40px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    z-index: 4;
}
.widget-pack .image-link:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    /*background: rgba(242, 242, 242, 0.4);*/
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%) inset;
    background-color: transparent;
    z-index: 11;
}

.widget-pack .poster {
    position: relative;
    /*border: 1px solid transparent;*/
    border-radius: 50%;
}

.widget-pack .text-link {
    cursor: default;
}

.widget-pack:not(.full) .text-link {
    cursor: pointer;
}

.widget-pack {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.widget-pack .descr {
    padding-top: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #7C7C7C;
}
.widget-pack .title {
    margin: 0;
    padding: 0;
    padding-top: 11px;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-align: center;
}

.widget-pack.full .title {
    padding-top: 18px;
}

.widget-pack.full .descr {
    padding-top: 15px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #A9A9A9;
}

@media (max-width: 576px) {
    .widget-pack img {
        width: 116px;
        height: 116px;
    }
    .widget-pack .image-link {
        width: 116px;
        height: 116px;
    }
}

@media (max-width: 350px) {
    .widget-pack img {
        width: 95px;
        height: 95px;
    }
    .widget-pack .image-link {
        width: 95px;
        height: 95px;
    }
}
