.likes {
  margin-top: 65px;

  &__illustrations {
    & > * {
      margin-bottom: 30px;
    }
  }

  .EmptyPageFiller {
    margin-top: 130px;
  }

  .MuiSkeleton-root {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
  }
}
