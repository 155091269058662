.ant-popover {
  z-index: 1500;
}

.ant-popover .ant-popover-inner {
  border-radius: 10px;
}

.ant-popover .ant-popover-arrow {
  display: none;
}

.ant-popover .ant-popover-inner-content {
  min-width: 180px;
  max-width: 200px;
  padding: 28px;
}

@mixin description {
  display: block;
  margin: 5px 0 0;
  padding: 0;

  font-family: "Euclid Circular A" , "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  border: none;
  background-color: transparent;
  text-align: left;
}

.popup-element {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  row-gap: 15px;

  &__item {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }

  &__title {
    margin: 0;
    padding: 0;
    font-family: "Euclid Circular A" , "Arial", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #A9A9A9;
  }

  &__description_plain {
    @include description;
  }

  &__description_link {
    @include description;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  &__description_link:hover {
    cursor: pointer;
    color: #000000;
    opacity: 0.6;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item:not(:last-child) {
    margin-right: 10px;
  }

}

@media (max-width: 575px) {
  .popup-element {
    &__description {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
