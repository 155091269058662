.product-info__keywords {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-info span.MuiSkeleton-root {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
}
.product-info .illustrations {
  margin-top: 40px;
}
.product-info .info-button {
  position: absolute;
  top: 10px;
  right: 10px;

  font-family: "Euclid Circular A" , "Arial", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 5px;

  background: #F2F2F2;
  border-radius: 10px;
  z-index: 100;
  border: none;
  outline: 0px;
}
.product-info .info-button svg path {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.product-info .info-button:hover svg path {
  fill: #000000;
}
.product-info.in-modal .widget-single-product h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 115%;
  min-height: auto;

  text-align: center;
  letter-spacing: -2px;
  color: #000000;
  max-width: 700px;
}
.product-info.in-modal .button-icon {
  height: 40px;
}
.product-info .widget-single-product h1 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 70px;
  min-height: auto;

  text-align: center;
  letter-spacing: -2px;
  color: #000000;
}
.product-info .illustration-row {
  padding-top: 40px;
}
.product-info .like svg path {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.product-info .like:hover svg path {
  fill: #F76567;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.product-info .product-info__description {
  margin: 0;
  padding: 80px 12% 60px;
  white-space: pre-wrap;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000;
}

.product-info .product-info__keywords {
  padding: 20px 12% 0px;
}

.widget-single-product h1 {
  min-height: 70px;
  font-style: normal;
  font-weight: 600;
  font-size: 62px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -3px;
  color: #000000;

  word-wrap: break-word;
}
.widget-single-product .top {
  display: flex;
  justify-content: center;
}
.widget-single-product .middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.widget-single-product .middle .MuiSkeleton-root {
  margin: 0 auto;
  border-radius: 10px;
}
.widget-single-product .bottom {
  padding-top: 25px;

  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: center;
}

@media screen and (max-width: 1270px) {
  .widget-single-product h1 {
    font-size: 55px;
    line-height: 60px;
    letter-spacing: -3px;
  }
}

@media (max-width: 991px) {
  .product-info .product-info__description,
  .product-info.in-modal .product-info__description {
    padding: 60px 0 40px;
  }
  .product-info .product-info__keywords,
  .product-info.in-modal .product-info__keywords {
    padding: 20px 0 0px;
  }

  .widget-single-product .middle {
    margin-top: 25px;
  }
  .product-info .widget-single-product h1 {
    font-size: 50px;
    line-height: 50px;
  }
}


@media (max-width: 400px) {
  .widget-single-product .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0;
  }
  .widget-single-product span.MuiSkeleton-root{
    margin-top: 15px;
  }
  .widget-single-product .btn-wrapper:nth-last-child(1) {
    order: -1;
    margin-bottom: 10px;
  }
  .widget-single-product h1 {
    font-size: 42px;
    line-height: 55px;
  }
  .product-info.in-modal .widget-single-product h1 {
    font-size: 38px;
  }
}
