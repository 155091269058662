.EmptyPageFiller {
  text-align: center;

  picture {
    display: block;
    width: 100%;
  }

  img {
    max-width: 100%;
    margin-bottom: 50px;
  }
}
