.skeleton-button .MuiSkeleton-root {
    margin: 30px auto 0;
    width: 225px;
    height: 50px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
}

@media screen and (max-width: 375px) {
    .skeleton-button .MuiSkeleton-root {
        width: 100%;
    }
}
