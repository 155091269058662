$grey-main-color: #a9a9a9;
$grey-light-color: #e7e7e7;
$grey-dark-color: #7c7c7c;
$grey-border-color: #f2f2f2;
$yellow-color: #ffdf57;
$yellow-dark: #e7c94e;

//Buttons colors
$primary-color: #2c3fee;
$blue-color: $primary-color;
$secondary-color: #9130de;
$violet-color: $secondary-color;
$third-color: #25ea5c;
$green-color: $third-color;
$red-color: #fe3434;
$danger-color: $red-color;
