@import './common_styles/colors';

@font-face {
  font-family: "Object Sans";
  src: url("./fonts/ObjectSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Regular-WebXL.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Medium-WebXL.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Euclid Circular A";
  src: url("./fonts/EuclidCircularA-Semibold-WebXL.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "DM Sans";
  src: url("./fonts/dmsans-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
  url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
  format("woff2");
  font-display: swap;
}

body {
  font-family: "Euclid Circular A", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

::selection {
  background: #FF2067; /* Цвет фона */
  color: #fff; /* Цвет текста */
}

.App {
  display: flex;
  min-height: calc(100vh - 61px);
  align-items: stretch;
  justify-content: stretch;

  &__sidebar {
    display: none;
  }

  &__content {
    flex: 1;
  }

  &_dev {
    @extend .App;

    .App__sidebar {
      flex: 0.3;
      background-color: #f2f2f2;
      display: flex;
      padding: 15px 25px;

      ul {
        padding-left: 0;
        list-style: none;

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .text-link {
    color: $blue-color;
    transition: color 0.3s ease;

    &:hover {
      cursor: pointer;
      color: lighten($blue-color, 20);
    }

    &_black {
      @extend .text-link;
      color: #000;

      &:hover {
        color: lighten(#000, 20);
      }
    }
  }
}
