.badge {
    width: max-content;
    padding: 0 6px;

    height: 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    color: #000000;
    border-radius: 5px;
    border: 1px solid transparent;
    margin-bottom: 8px;

    cursor: pointer;
}

.badge:not(:last-child) {
    margin-right: 8px;
}

.badge.green {
    background: #25EA5C;
}

.badge.purple {
    background-color: #9130DE;
    color: #ffffff;
}
.badge.blue {
    background: #2C3FEE;
    color: #ffffff;
}
.badge.outline {
    font-weight: 500;
    background-color: transparent;
}
.badge.outline.neutral {
    color: #A9A9A9;
    border-color: #E7E7E7;
}
.badge.outline.neutral:hover {
    border-color: #F2F2F2;
    background: #F2F2F2;
    color: #000000;
}
.badge.large {
    padding: 0 6px;
    height: 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    background: #F2F2F2;
}
.badge.status {
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: #ffffff;
}
.badge.status.strong {
    background-color: #9FE038;
}
.badge.status.medium {
    background-color: #F5D733;
}
.badge.status.weak {
    background-color: #FFA621;
}
.badge.status.very-weak {
    background-color: #FE3434;
}
