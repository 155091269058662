@import "../common_styles/colors";

$AccountFromBorderRadius: 10px;

.AccountForm {
  &__hint {
    color: $grey-main-color;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;

    p {
      margin-bottom: 0;
    }
  }

  .AccountDelimiter {
    margin-bottom: 27px;
  }

  .AccountSignLink {
    margin-top: 40px;
  }

  .ant {
    &-form-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-label  {
        text-align: left;
        width: 100%;
        max-width: 100%;
        margin-left: 0;

        & > label {
          color: $grey-main-color;
          font-size: 16px;
          line-height: 24px;
          width: 100%;
        }
      }
  
      &-control {
        width: 100%;
        max-width: 100%;
        margin-left: 0;
      }
    }

    &-input {
      border-radius: $AccountFromBorderRadius;
      font-size: 20px;
      line-height: 25px;
      padding: 12px 20px 13px;

      &::placeholder {
        font-size: 20px;
        line-height: 25px;
      }

      &-affix-wrapper {
        border-radius: $AccountFromBorderRadius;
        padding: 12px 20px 13px;

        input {
          border-radius: 0;
        }
      }

      &-prefix {
        margin-right: 10px;
      }
    }

    &-btn {
      width: 100%;
      border-radius: $AccountFromBorderRadius;
      font-size: 20px;
      line-height: 25px;
      height: auto;
      padding: 12px;
      color: #fff;
      background-color: #000;
      border-color: #000;

      &:hover {
        background-color: lighten(#000, 20);
        border-color: lighten(#000, 20);
      }
    }

    &-form-item {
      &-label {
        & > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
          &::before {
            display: none;
          }
        }

        & > label {
          &::after {
            display: none;
          }
        }
      } 

      &-has-success {
        .ant-form-item-children-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &-has-error {
        .ant-form-item-children-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &-checkbox {
      font-size: 16px;
      line-height: 24px;

      &-wrapper {
        color: $grey-dark-color;
        font-size: 16px;
        line-height: 24px;
      }

      .ant-checkbox-inner {
        border-color: #000;
        border-radius: 5px;
        width: 22px;
        height: 22px;
      }

      &-checked {
        .ant-checkbox-inner {
          background-color: #000;

          &::after {
            left: 30%;
          }
        }
  
        &::after {
          border: 1px solid #000;
          border-radius: 5px;
        }
      }
    }
  }

  &__label_withRightLink {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .checkbox {
      margin-bottom: 0;
    }

    a {
      font-size: 16px;
      line-height: 24px;
      color: #000;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 24px;
    color: #000;

    &:hover {
      color: $grey-dark-color;
    }
  }

  .ErrorField {
    margin-bottom: 24px;
  }

  .SuccessField {
    margin-bottom: 24px;
  }
}  

