.ErrorField {
  padding: 20px;
  color: #fe3434;
  background-color: #ffebeb;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
