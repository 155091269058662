.subscription {
  margin-top: 65px;
  margin-bottom: 171px;

  .MainTitle {
    margin-bottom: 40px;
  }

  .SubTitle {
    margin-bottom: 30px;
  }

  &__backward-button {
    margin-bottom: 30px;
  }

  .InfoField {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__cancel-button {
    margin-top: 48px;
  }
}
