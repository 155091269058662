.illustrations-list .MuiSkeleton-root {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
}

.illustrations-list {
    display: grid;
    -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    grid-row-gap: 30px;
    row-gap: 30px;
}

.illustrations-list-col3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.illustrations-list-col5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1850px) {
    .illustrations-list-col5 {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1510px) {
    .illustrations-list-col5 {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 1270px) {
    .illustrations-list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 991px) {
    .illustrations-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 575px) {
    .illustrations-list {
        grid-template-columns: 1fr;

        grid-row-gap: 20px;
        row-gap: 20px;
    }
}
