@import "../common_styles/colors";

.SignBlock {
  .ant-btn {
    border-color: #000;
    background-color: #fff;
    color: #000;
    box-shadow: none;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 25px;
    overflow: hidden;
    
    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: #fff;
      border-color: #000;
      background-color: #000;
    }

    img {
      margin-right: 10px;
    }
  }
}
