@import "colors";

@mixin buttonColors($color, $hoverAmount, $activeAmount) {
  color: #fff;
  background-color: $color;
  border-color: $color;

  &:not(:disabled):not(.disabled).hover,
  &:not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).focus,
  &:not(:disabled):not(.disabled):focus {
    color: #fff;
    background-color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: $grey-main-color;
    border-color: $grey-main-color;
    cursor: not-allowed;
  }
}

@mixin outlineButtonColors($color, $hoverAmount, $activeAmount) {
  background-color: #fff;
  color: $color;
  border-color: $color;

  &:not(:disabled):not(.disabled).hover,
  &:not(:disabled):not(.disabled):hover {
    background-color: #fff;
    color: lighten($color, $hoverAmount);
    border-color: lighten($color, $hoverAmount);
  }

  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).focus,
  &:not(:disabled):not(.disabled):focus {
    background-color: #fff;
    color: darken($color, $activeAmount);
    border-color: darken($color, $activeAmount);
  }

  &.disabled,
  &:disabled {
    color: $grey-main-color;
    background-color: #fff;
    border-color: $grey-main-color;
    cursor: not-allowed;
  }
}

.btn{
  font-weight: 600;
  border-radius: 10px;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.5px;
  padding: 7px 15px;
  border-width: 2px;

  &__icon {
    vertical-align: baseline;

    &_right {
      @extend .btn__icon;

      margin-right: 14px;
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }

  &-primary {
    @extend .btn;

    @include buttonColors($primary-color, 20, 20);
  }

  &-secondary {
    @extend .btn;

    @include buttonColors($secondary-color, 20, 20);
  }

  &-third {
    @extend .btn;

    @include buttonColors($third-color, 20, 20);
    color: #000;

    svg {
      path {
        fill: #000;
      }
    }

    &:hover {
      color: lighten(#000, 20);

      svg {
        path {
          fill: lighten(#000, 20);
        }
      }
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      color: darken(#000, 20);

      svg {
        path {
          fill: #000;
        }
      }
    }
  }

  &-danger {
    @extend .btn;

    @include buttonColors($danger-color, 20, 20);
  }

  &-black {
    @extend .btn;

    @include buttonColors(#000, 20, 20);
  }

  &-outline {
    @extend .btn;

    background-color: #fff;
    color: #000;
    border-color: $grey-border-color;

    &:hover{
      background-color: #fff;
      color: lighten(#000, 40);
      border-color: $grey-light-color;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active,
    &:active {
      background-color: #fff;
      color: darken(#000, 20);
      border-color: $grey-main-color;
    }

    &_light {
      @extend .btn-outline;

      font-weight: 500;
    }
  }

  &-outline-primary {
    @extend .btn;

    @include outlineButtonColors($primary-color, 20, 20);
  }

  &-outline-secondary {
    @extend .btn;

    @include outlineButtonColors($secondary-color, 20, 20);
  }

  &-outline-danger {
    @extend .btn;

    @include outlineButtonColors($danger-color, 20, 20);
  }

  &-outline-black {
    @extend .btn;

    @include outlineButtonColors(#000, 20, 20);
  }
}
