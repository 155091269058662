.SuccessField {
  padding: 20px;
  color: #08910f;
  background-color: #e6f4e7;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
