.SettingsSidebar {
  list-style: none;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__item {
    margin-bottom: 0;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: #000;

    &:hover {
      color: #b9b9b9;
    }

    &_active {
      @extend .SettingsSidebar__link;
      color: #a9a9a9;
    }
  }
}

@media (min-width: 768px) {
  .SettingsSidebar {
    flex-direction: column;

    &__item {
      margin-bottom: 25px;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
