.login {
  padding-top: 35px;
  padding-bottom: 35px;
  min-height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__picture-block {
    width: 100%;
    height: 100%;
    
    picture {
      display: block;
      width: 100%;
      height: 100%;
    
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .MainTitle {
    margin-bottom: 40px;
  }

  .SignBlock {
    margin-bottom: 30px;
  }
}
