@import "../common_styles/colors";

.SubscriptionPlanCard {
  width: 255px;
  display: flex;
  flex-direction: column;

  %header-shared {
    background-color: $blue-color;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 24px;
    border-radius: 10px 10px 0 0;
    position: relative;
  }

  &__header_show {
    @extend %header-shared;
    display: flex;
    justify-content: center;
  }

  &__header_hide {
    @extend %header-shared;
    display: none;
  }

  &__header-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    margin: 0;
  }

  &__header-wrap-info {
    width: 16px;
    height: 24px;
    position: absolute;
    top: 0px;
    right: 4px;
    box-sizing: border-box;
  }

  &__header-wrap-info:hover {
    cursor: help;
  }

  &__header_show svg {
    position: absolute;
    top: 4px;
    right: 0px;
    opacity: 0.5;
  }

  %body-shared {
    padding: 15px 20px 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__body_current {
    @extend %body-shared;
    border: 1px solid $blue-color;
  }

  &__body_current-old {
    @extend %body-shared;
    border: 1px solid $blue-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__body_other {
    @extend %body-shared;
    border: 1px solid $grey-light-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &__body_best {
    @extend %body-shared;
    background-color: $yellow-color;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid $yellow-color;
    border-bottom: 1px solid $yellow-dark;
  }

  &__plan-info {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
  }

  &__name {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.04em;
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__downloads {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    opacity: 0.6;
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__plan-price {
    display: flex;
    align-items: baseline;
    margin-top: 12px;
    margin-bottom: 20px;
  }

  &__price {
    font-weight: 600;
    font-size: 50px;
    line-height: 100%;
    letter-spacing: -3px;
    color: #000;
    margin-bottom: 0;
  }

  &__duration {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #000;
    margin-bottom: 0;
  }

  &__per-piece {
    display: flex;
    flex-direction: row;
    width: max-content;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 3px 8px 3px 10px;
  }

  &__per-piece svg {
    margin-top: 5px;
    margin-right: 8px;
  }

  &__per-piece-price {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #000;
    margin-bottom: 0;
  }

  %button-container-shared {
    padding: 20px;
    border-style: solid;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-right-width: 1px;
    border-top-width: 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__button-container_current {
    @extend %button-container-shared;
    border-color: $blue-color;
  }

  &__button-container_current-old {
    display: none;
  }

  &__button-container_other {
    @extend %button-container-shared;
    border-color: $grey-light-color;
  }

  &__button-container_best {
    @extend %button-container-shared;
    border-color: $yellow-color;
    background-color: $yellow-color;
  }

  .btn {
    width: 100%;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    border-width: 1px;
  }
}

.subscriptions__plan-cards-with-current .SubscriptionPlanCard {
  &__header-wrap-info {
    display: none;
  }
}

@media (max-width: 767px) {
  .SubscriptionPlanCard {
    margin: 0 auto;
  }
}
