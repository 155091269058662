.button-icon {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-gap: 9px;
    align-items: center;

    height: 40px;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid transparent;

    cursor: pointer;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    outline: none !important;
}
.button-icon.small {
    font-weight: 600;
    padding: 0 7px;
    grid-gap: 4px;
    grid-template-columns: auto 1fr;
    height: 26px;
    font-size: 12px;
    line-height: 11px;
}
.button-icon.small svg {
    width: 19px;
}
.button-icon.large {
    font-size: 18px;

    height: 50px;
}
.button-icon svg {
    vertical-align: middle;
    margin-top: -5px;
}
.button-icon span:nth-of-type(2) {
    display: flex;
    align-items: center;
}
.button-icon span:nth-of-type(2) .ant-avatar {
    margin-right: 12px;
}
.button-icon .ant-avatar:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%) inset;
    z-index: 100;
}
.button-icon span:first-of-type {
    text-align: center;
}
.button-icon.black {
    background: #000000;
    color: #ffffff;
}
.button-icon.black:hover {
    background-color: rgba(0, 0, 0, 0.8);
}
.button-icon.purple {
    color: #ffffff;
    background-color: rgba(145, 48, 222, 1);
}
.button-icon.purple:hover {
    background-color: rgba(145, 48, 222, 0.8);
}
.button-icon.blue {
    background: #2C3FEE;
    color: #ffffff;
}
.button-icon.blue:hover {
    background: rgba(44, 63, 238, 0.8);
}
.button-icon.neutral {
    background: #F2F2F2;
    color: #000000;
}
.button-icon.neutral:hover {
    background: #E5E5E5;
}
.button-icon.light-blue {
    background: rgba(56, 120, 234, 1);
    color: #ffffff;
}
.button-icon.light-blue:hover {
    background: rgba(56, 120, 234, 0.8);
}
.button-icon.outline {
    background-color: transparent;
}

.button-icon.outline.neutral {
    border-color: #F2F2F2;
}
.button-icon.outline.neutral:hover {
    background-color: transparent;
    border-color: #CBCBCB;
}
.button-icon.outline.black {
    border-color: #000000;
    color: #000000;
}
.button-icon.outline.black:hover {
    border-color: #CBCBCB;
    color: #000000;
    background-color: transparent;
}

.button-icon.sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

.button-icon.outline.neutral.sticky {
    background-color: #fff;
}

.button-icon.outline.neutral.sticky:hover {
    background-color: #fff;
}
