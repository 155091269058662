@import '../../common_styles/colors';

.Order {
  margin-top: 65px;
  margin-bottom: 171px;

  .MainTitle {
    margin-bottom: 40px;
  }

  .EmptyPageFiller {
    margin-top: 120px;
  }
}  
