.ErrorBoundary {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ErrorBoundary a {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #2c3fee;
  margin-bottom: 10px;
}

.ErrorBoundary a:hover {
  opacity: 0.6;
}
