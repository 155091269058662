@import '../../common_styles/colors';

.checkout {
  margin-top: 65px;
  margin-bottom: 65px;
  min-height: calc(100% - 194px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .text-link {
      margin-bottom: 30px;
    }
  }

  &__order {
    margin-bottom: 30px;
  }

  &__details {
    margin-bottom: 30px;
  }

  .MainTitle,
  .InfoCard {
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    color: $grey-dark-color;
    overflow: hidden;
  }

  .SummaryCard {
    margin-top: 50px;
  }

  .btn {
    width: 100%;
    margin-bottom: 32px;
    font-weight: 400;
  }
} 
