@import "../common_styles/colors";

.AccountDelimiter {
  position: relative;
  color: $grey-main-color;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;

  &::after {
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    width: 100%;
    background-color: $grey-light-color;
    z-index: 1;
    top: calc(50% + 1px);
  }

  &__text {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    padding: 0 8px;
    z-index: 2;
    background-color: #fff;
  }
}
