@import "../../common_styles/colors";

.subscriptions {
  margin-top: 65px;
  margin-bottom: 171px;

  .MainTitle {
    margin-bottom: 30px;
  }

  &__table {
    margin-bottom: 70px;
  }

  %plan-cards-shared {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 60px;
    gap: 30px;
  }

  &__plan-cards-no-current {
    @extend %plan-cards-shared;
    align-items: stretch;
  }

  &__plan-cards-with-current {
    @extend %plan-cards-shared;
    align-items: flex-end;
  }

  &__section-title {
    width: 100%;
    height: 30px;
    border-bottom: 1px solid $grey-light-color;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $grey-main-color;
    margin: 30px 0;
  }

  .EmptyPageFiller {
    margin-top: 120px;
  }
}

@media (max-width: 767px) {
  .subscriptions .subscriptions__plan-cards-no-current,
  .subscriptions .subscriptions__plan-cards-with-current {
    justify-content: center;
  }
}
