 .browse-by-illustrations .header {
   display: flex;
   justify-content: center;
 }

 .browse-by-illustrations .MuiSkeleton-root {
   border-radius: 10px;
   background-color: rgba(0, 0, 0, 0);
   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
 }

 .browse-by-illustrations .body {
   margin-top: 30px;
 }

 .browse-by-illustrations .footer {
   margin-top: 40px;
 }

 .browse-by-illustrations .header .title {
     margin: 0;
     padding: 0;
     font-family: "Euclid Circular A", "Arial", sans-serif;
     font-style: normal;
     font-weight: 600;
     font-size: 28px;
     line-height: 36px;
     letter-spacing: -0.5px;
     color: #000000;
 }

 @media screen and (max-width: 1510px) {
     .browse-by-illustrations .header .title {
         font-size: 22px;
         line-height: 36px;
     }
 }

 @media screen and (max-width: 991px) {
     .browse-by-illustrations .header .title {
         font-size: 20px;
         line-height: 31px;
     }
 }

 @media screen and (max-width: 767px) {
     .browse-by-illustrations .header .title {
         font-size: 22px;
         line-height: 36px;
     }
 }
