@import "colors";

.settingsTable {
  .ant-table {
    font-size: 16px;
    line-height: 24px;

    &-cell {
      white-space: nowrap;
    }
  
    &-thead {
      background-color: #fff;
  
      .ant-table-cell {
        background-color: #fff;
        color: #a9a9a9;
        font-weight: 500;
        border-bottom: none;
        padding: 13px 5px;

        &:first-child {
          padding: 13px 5px 13px 18px;
        }
        &:last-child {
          padding: 13px 18px 13px 5px;
        }
      }
    }

    &-row {
      .ant-table-cell {
        padding: 13px 5px;

        &:first-child {
          padding: 13px 5px 13px 18px;
        }

        &:last-child {
          padding: 13px 18px 13px 5px;
        }
      }
    }
  }

  

  &__link {
    text-decoration: underline;
    color: #000;

    &:hover {
      color: #a9a9a9;
      cursor: pointer;
      transition: color 0.3s ease;
      text-decoration: none;
    }

    &_destructive {
      @extend .settingsTable__link;

      color: #fe3434;

      &:hover {
        color: lighten(#fe3434, 20);
      }
    }
  }

  &_subs {
    @extend .settingsTable;

    .ant-table {
      &-row {
        border-bottom: none;
        border-radius: 10px;

        .ant-table-cell {
          background-color: #f9f9f9;
          border-bottom: none;
        }

        &:first-child {
          .ant-table-cell{
            &:first-child {
              border-top-left-radius: 10px;
            }
  
            &:last-child {
              border-top-right-radius: 10px;
            }
          }
        }

        &:last-child {
          .ant-table-cell{
            &:first-child {
              border-bottom-left-radius: 10px;
            }
  
            &:last-child {
              border-bottom-right-radius: 10px;
            }
          }
        }
      }
    }

    &_styled-first {
      @extend .settingsTable_subs;
      .ant-table {
        &-row {
          .ant-table-cell{
            &:first-child {
              color: $grey-dark-color;
            }
          }  
        }
      }  
    }

    &_one_column {
      @extend .settingsTable_subs;

      .ant {
        &-table-row,
        &-table-thead {
          .ant-table-cell {
            padding: 13px 18px;
    
            &:first-child {
              padding: 13px 18px;
            }
    
            &:last-child {
              padding: 13px 18px;
            }
          }
        }
      }
    }
  }
}
