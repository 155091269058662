@import '../../common_styles/colors';

.settings {
  margin-top: 65px;
  margin-bottom: 171px;

  .MainTitle {
    margin-bottom: 40px;
  }

  .InfoField {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  #SettingsForm {
    max-width: 445px;

    .ant-btn {
      margin-top: 48px;
      background-color: $primary-color;
      border-color: $primary-color;
      width: auto;
      padding: 12px 23px;

      &:hover {
        background-color: lighten($primary-color, 20);
        border-color: lighten($primary-color, 20);
      }
    }
  }
}
