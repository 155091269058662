.product {
  position: relative;
  padding-bottom: 75%;
  border-radius: 10px;
  border-color: rgba(255,255,255,0);
  border-width: 0;
  overflow: visible;
  cursor: pointer;
  background-color: rgba(255,255,255,0);
  background-clip: content-box;
}
.product img,
.product picture,
.product .lazyload-wrapper {
  width: 100%;
  height: initial;
  z-index: 30;
}
.product .product__picture {
  height: 100%;
  width: 100%;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px;
  border-style: solid;
  overflow: hidden;
  background-color: rgba(255,255,255,0);
  background-clip: content-box;
  /* this is for safari for border-radius to work with overflow: hidden --> */
  transform: translateZ(0);
  /* <-- */

  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.product .product__picture:after {
  content: '';
  position: absolute;
  background-image: url("https://storytale-public2.b-cdn.net/static/assets/storytale-app/img/placeholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 3;
}
.illustrations .product .product__picture {
  border-color: #f2f2f2;
  border-width: 1px;
}
.illustrations-list .product .product__picture {
  border-color: rgba(255,255,255,0);
  border-width: 0;
}
.illustrations-list .product .product__shade {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  border-color: rgba(255,255,255,0);
  border-width: 0;
  z-index: 100;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  pointer-events: none;
}
.illustrations-list .product .product__shade.product__shade_gray {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
  opacity: 1;
}
.illustrations-list .product .product__shade.product__shade_white {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 45%, rgba(255, 255, 255, 0.8) 100%);
  opacity: 0;
}

.illustrations-list .product .product__free {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 100;
  user-select: none;
  pointer-events: none;

  background-color: #F2F2F2;
  border-radius: 12px;
  min-height: 24px;
  min-width: 63px;
  box-sizing: border-box;

  padding: 3px 9px 3px 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #7C7C7C;
}

.illustrations-list .product .product__info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 11px 10px 15px;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  pointer-events: none;
}
.illustrations-list .product .product__texts {
  z-index: 100;
  opacity: 0;
  transition: opacity 0.2s linear;
  -webkit-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  pointer-events: none;
  box-sizing: border-box;
  padding-right: 5px;
  flex-grow: 1;
  /*  this is important to "overflow: hidden" to work, DO NOT DELETE --> */
  min-width: 0;
  /* <-- */
}
.illustrations-list .product .product__name {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}
.illustrations-list .product .product__pack-name {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  opacity: 0.6;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
}
.illustrations-list .product .product__likes-count {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #A2A2A2;
  margin: 0 2px 0 3px;
}
.illustrations-list .product .btn-wrapper {
  display: flex;
  flex-direction: row;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: translate3d(0,0,0);
  pointer-events: auto;
}
.illustrations-list .product .dialog-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 36px;
  background-color: #f8f8f8;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  padding: 5px 12px;
  outline: 0 !important;
}
.illustrations-list .product .dialog-link svg path{
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.illustrations-list .product .dialog-link-download {
  margin-left: 5px;
}

/* on hover */
.illustrations .product:hover .product__picture {
  box-shadow: none;
  cursor: default;
}
.illustrations-list .product:hover .product__picture {
  -webkit-box-shadow: 0 20px 50px rgba(0,0,0,.07) !important;
  box-shadow: 0 20px 50px rgba(0,0,0,.07) !important;
  z-index: 90;
}
.illustrations-list .product:hover .product__shade.product__shade_gray {
  opacity: 0;
}
.illustrations-list .product:hover .product__shade.product__shade_white {
  opacity: 1;
}
.illustrations-list .product:hover .product__likes {
  opacity: 1;
}
.illustrations-list .product:hover .product__texts {
  opacity: 1;
  flex-direction: column;
}
.illustrations-list .product:hover .btn-wrapper {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}
.illustrations-list .product .dialog-link-like:hover svg path {
  fill: #FE3434;
}
.illustrations-list .product .dialog-link-download:hover svg path {
  fill: #9130DE;
}

.ant-tooltip-inner {
  padding: 10px 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  border-radius: 10px;
}

.ant-tooltip-arrow-content {
  display: none;
}
