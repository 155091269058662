.modal {
  &-backdrop {
    &.show {
      opacity: 0.4;
    }
  }

  .modal-header .MuiSkeleton-root {
    border-radius: 5px;
  }

  .section {
    margin-top: 100px;
  }

  .modal-header .button-icon {
    position: absolute;
    z-index: 999;
  }

  .close-button {
    margin-left: auto;
    margin-top: -5px;
    cursor: pointer;

    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    svg {
      -webkit-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    &:hover svg {
      transform: rotate(45deg);
    }
  }

  &-content {
    border-radius: 15px;
    border: none;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.1);
  }

  &-dialog {
    .description {
      max-width: 395px;
      margin: 0 auto;

      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 130%;

      text-align: center;
      letter-spacing: -0.5px;

      color: #000000;

      &-small {
        padding-top: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 145%;
        color: #7C7C7C;
      }

      &-tiny {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: #7C7C7C;
      }
    }

    .short-description {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 160%;
      color: #000000;
    }

    &-subscribtion .modal-footer,
    &-sign-up .modal-footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }

    &-subscription-error .modal-footer p,
    &-subscription-error .modal-body p:not(.short-description),
    &-download-limit .modal-footer p,
    &-download-limit .modal-body p:not(.short-description) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #000000;
    }

    &-download-limit .modal-body {
      padding-bottom: 30px;
    }

    &-subscription-error .modal-body {
      padding-bottom: 0;
    }

    &-download-limit .image-container,
    &-subscription-error .image-container {
      display: flex;
      justify-content: center;

      margin-bottom: 15px;
    }


    &-subscription-error .modal-body p:not(.short-description),
    &-download-limit .modal-body p:not(.short-description) {
      margin: 0 auto;
      margin-top: 5px;
      max-width: 250px;

      color: #7C7C7C;
    }

    &-download-limit .modal-body p:not(.short-description) {
      max-width: 240px ;
    }

    &-download-limit .modal-footer {
      background-color: #F8F8F8;
    }

    &-download-limit  .modal-footer .button-base {
      margin-top: 20px;
    }

    &-download-limit .short-description,
    &-subscription-error .short-description {
      font-weight: 600;
    }

    &-subscribtion {
      max-width: 590px;
    }

    &-subscription-error,
    &-download-limit {
      max-width: 335px;
    }

    &-unlimited,
    &-sign-up {
      max-width: 450px;
    }

    &-illustration-info {
      max-width: 1040px;
      padding-right: 30px;
      padding-left: 30px;

      &-modal-body {
        padding: 40px;
      }
    }
  }

  &-header {
    padding-bottom: 0;
    border: none;

    button {
      position: relative;
      z-index: 100;
    }
  }

  &-body {
    padding: 40px;
    padding-top: 25px;
  }

  &-footer {
    padding: 37px;
    padding-top: 20px;
    justify-content: center;
    border-top: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    & >:not(:first-child) {
      margin-left: 0;
    }

    & >:not(:last-child) {
        margin-right: 0;
    }
  }

  .MuiSkeleton-root {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.03) 100%);
  }
}

.custom-backdrop {
  z-index: 1050;
}

.modal-dialog-plans {
  max-width: 1100px;
}

.modal-dialog-plans .plans .wrapper-container {
  padding-top: 0;
}

@media screen and (max-width: 1270px) {
  .modal .section {
    margin-top: 70px;
  }
}

@media (max-width: 1199px) {
  .modal-dialog-plans {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .modal {
    &-dialog {
      &-subscribtion .modal-footer {
        grid-template-columns: auto;

        button {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
}

.modal-dialog-sign-up .before-footer {
  margin-top: 40px;
}
.modal-dialog-unlimited .modal-body,
.modal-dialog-unlimited .modal-footer,
.modal-dialog-sign-up .modal-body,
.modal-dialog-sign-up .modal-footer {
  padding-left: 90px;
  padding-right: 90px;
}
.modal-dialog-sign-up .modal-footer {
  padding-top: 0;
}
.modal-dialog-unlimited .description-tiny {
  max-width: 230px;
}
.modal-dialog-unlimited .big-link {
  margin-top: 40px;
}
.modal-dialog .description-tiny > a {
  color: rgba(0, 0, 0, 1);
}
.modal-dialog .description-tiny > a:hover {
  color: rgba(0, 0, 0, 0.6);
}
.modal-dialog-sign-up .socials-wrapper {
  padding-top: 40px;
}
.modal-dialog-sign-up .button-icon {
  margin-bottom: 15px;
}
.modal-dialog-sign-up .button-icon:nth-last-of-type(1) {
  margin-bottom: 0;
}
.modal-dialog-sign-up .description-tiny {
  margin-top: 40px;
}

@media (max-width: 1510px) {

}

@media (max-width: 768px) {
  .modal-dialog-subscribtion .modal-footer {
    grid-template-columns: auto;
  }

  .modal-dialog-plans .SubscriptionPlanCard__downloads p:first-child {
    font-size: 60px !important;
  }

  .modal-dialog {
    width: 90%;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .modal-dialog-plans {
    width: 95%;
    margin: 0 auto;
  }

  .modal-dialog-plans .modal-body {
    padding: 12px;
  }
}

@media (max-width: 576px) {
  .modal-dialog-unlimited .modal-body,
  .modal-dialog-unlimited .modal-footer,
  .modal-dialog-sign-up .modal-body,
  .modal-dialog-sign-up .modal-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .modal-dialog-illustration-info .modal-body {
      margin-top: 30px;
      padding: 20px;
      padding-top: 10px;
  }

}

@media (max-width: 400px) {
  .modal-footer {
    padding: 20px;
  }
  .modal-dialog .description {
    font-size: 26px;
  }
  .modal-dialog .big-link {
    font-size: 18px;
  }
  .modal-dialog-unlimited .modal-body,
  .modal-dialog-unlimited .modal-footer,
  .modal-dialog-sign-up .modal-body,
  .modal-dialog-sign-up .modal-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 375px) {
  .modal-dialog-unlimited .modal-body,
  .modal-dialog-unlimited .modal-footer {
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-dialog-sign-up .button-icon {
    font-size: 17px;
  }

  .popup {
    width: 300px;
    left: 50%;
    margin-left: -150.0px;
  }
}
