.browse-by-packs .body {
    margin-top: 30px;
}

.browse-by-packs .header .title {
    margin: 0;
    padding: 0;
    font-family: "Euclid Circular A", "Arial", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.5px;
    color: #000000;
}

.browse-by-packs .footer {
    margin-top: 40px;
}

@media screen and (max-width: 1510px) {
    .browse-by-packs .header .title {
        font-size: 22px;
        line-height: 36px;
    }
}

@media screen and (max-width: 991px) {
    .browse-by-packs .header .title {
        font-size: 20px;
        line-height: 31px;
    }
}

@media screen and (max-width: 767px) {
    .browse-by-packs .header .title {
        font-size: 22px;
        line-height: 36px;
    }
}